<template>
  <portal to="modal">
    <div class="modal-content modal-dialog-centered">
      <div class="overlaylogin" id="overlaylogin">
        <div class="modal-customLogin">
          <component :is="component" v-bind="$attrs" />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: "Modal",
  props: ["component"],
  data() {
    return {
      nilai: 'none',
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.nilai == 'none' && document.getElementById('daftar_menu_id').style.display == 'block' || this.lebar === 640 || this.lebar < 640) document.getElementById('overlaylogin').style.top = "173.76px";
      document.getElementById('tombolMenu').addEventListener("click", function () {
        this.nilai = document.getElementById('daftar_menu_id').style.display;
        if (this.nilai == 'none') document.getElementById('overlaylogin').style.top = "135px";
        if (this.nilai == 'block') document.getElementById('overlaylogin').style.top = "173.76px";
      }
      );
    });
  },
  methods: {
  }
};
</script>

<style scoped>



.modal-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modal-customLogin {
    margin-left: 23.79%;
    margin-right: 23.79%;
    margin-top: 5%;
  width: 52.42%;
  height: auto;
  background-color: white;
  padding: 3em 5em 2em 5em;
  border: 1px solid #ccc;
  border-radius: 1em;
  box-shadow: 0 0 1em #00000033;
}

.overlaylogin {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 135px;
  width: 100vw;
  height: 853px;
  background: rgba(5, 58, 105, 0.38);
  background-color: rgba(#000000, 0.05);
} 
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .overlaylogin {
    width: 100%;
    z-index: 1;
      height: 1196px;
  }
    .modal-customLogin {
    width: 90%;
    height: auto;
    top: 23% !important;
        margin-left: 5% !important;
          margin-right: 5% !important;
    }

}
</style>
